html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.container, .wishlist-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  flex: 1 0 auto;
  padding-bottom: 60px; /* Adjust this value based on your footer height */
}

#root {
  min-height: 100%;
  min-width: 100%;
  /* Other styles for the background */
  background-color: #FFFFF0;
  /* background-image: url('styles/assets/rugbackground.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; */
}


.header-bar > .logo {
  display: inline;
}

.header-bar {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Adds depth with shadow */
  border-bottom: 0.1vh solid #d0d0d0; /* Darker border at the bottom for a 3D effect */
}

.logo-img {
  color: #1A1A1A;
  text-decoration: none;
  width: 10%;
  margin: 0 10% 0 2%;
  float: none;
}

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wishlist-container {
  position: relative;
  min-height: 100vh;
}

.big-container {
  display: grid;
  grid-template-columns: 15% auto;
  grid-auto-rows: min-content; /* Adjust row height based on content */
  margin-top: 2%;
  margin-left: 2%;
}

.filter-container {
  align-self: flex-start; /* Align to the flex-start of the grid area */
  background-color: #A7BED1;
  padding: 2% 2% 2% 4%; /* Uniform padding on all sides */
  opacity: 70%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; /* Align items to the flex-start to avoid unnecessary stretching */
}

.search-container label {
  font-size: 1.1vw;
  color: #1A1A1A;
  margin-bottom: 5px; /* Spacing between label and input box */
}

.search-container input[type="text"] {
  padding: 4px 8px; /* Reduced padding to shrink height */
  font-size: 0.8vw; /* Reduced font size */
  width: 97%; /* Keeping the original width to maintain left justification */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
  outline: none;
}

.search-container {
  margin-bottom: 4%; /* Padding below the entire container */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: left; /* Center the items */
}

.search-container input[type="text"]:focus {
  border-color: #007BFF; /* Color when the input is focused */
}

.reset-filters-button {
  background-color: #f44336; /* Red */
  color: white;
  border: none;
  padding: 0.25vw 1vw; /* Reduced vertical padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1vw;
  margin-bottom: 1vw;
  cursor: pointer;
  border-radius: 8%;
}


.dropdown-filter {
  padding-bottom: 4%;
}

.dropdown-filter > .title {
  font-size: 1.1vw;
  color: #1A1A1A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none; /* Remove the border */
  background: transparent; /* Optional: remove any background */
}

.dropdown-filter > .title:focus {
  outline: none; /* Remove default focus outline */
  border-radius: 0; /* Ensure sharp edges when focused */
}

/* If the dropdown is using a select element, also apply these styles */
.dropdown-filter select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  border: none; /* Remove border from select elements */
  background: transparent; /* Optional: remove any background */
}

.dropdown-filter select:focus {
  outline: none;
  border: none;
}

.dropdown-filter > .title .dropdown-arrow {
  font-size: 0.8vw;
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.dropdown-filter > .title .dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-filter > .content {
  font-size: 1vw; /* Increased from .7vw */
  line-height: 1.5; /* Increased from 1.3 */
  color: #1A1A1A;
}

/* Add this media query for desktop screens */
@media (min-width: 1024px) {
  .dropdown-filter > .content {
    font-size: 14px; /* Set a fixed size for larger screens */
  }

  .dropdown-filter > .title {
    font-size: 16px; /* Increase the title size for better proportion */
  }
}

.rug-sample {
  width: 16vw;
  height: 16vw;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 2% 5% 2% 0;
  transition: transform 0.2s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200%;
}

.rug-sample:hover {
  transform: scale(1.05);
}

#gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

/* Media query for larger screens */
@media (min-width: 1000px) {
  #gallery {
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns */
    gap: 20px; /* Adjust the gap between images */
    justify-items: center;
    margin-right: 2%;
  }
}

/* Media query for smaller screens */
@media (max-width: 1000px) {
  #gallery {
    grid-template-columns: repeat(3, 1fr); 
  }
  .rug-sample {
    width: 20vw;
    height: 20vw;
  }
  .caption {
    font-size: 2.5vw;
  }
}

/* Media query for even smaller screens */
@media (max-width: 768px) {
  #gallery {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; /* Reduce gap between items */
    margin-right: 0; /* Remove right margin to center content */
    padding: 0 10px; /* Add padding to prevent touching screen edges */
  }

  .rug-sample {
    width: 42vw; /* Slightly increase width to fill space */
    height: 42vw; /* Maintain aspect ratio */
    margin: 2% 0; /* Remove horizontal margin */
  }

  #imageLink {
    width: 100%; /* Make link full width of its grid cell */
  }

  .caption {
    font-size: 3vw;
    margin-top: 2%; /* Reduce top margin of caption */
  }

  .container, .wishlist-container {
    padding: 10px;
  }

  .dropdown-filter > .title .dropdown-arrow {
    font-size: 4vw; /* Increase arrow size for mobile */
    margin-left: 10px; /* Add more space between title and arrow */
  }

  .reset-filters-button {
    font-size: 3vw; /* Increase button text size */
    padding: 2vw 4vw; /* Increase button padding */
    margin-bottom: 3vw; /* Add more space below the button */
  }

  .dropdown-filter > .title {
    -webkit-appearance: none; /* Remove default iOS styling */
    -moz-appearance: none;
    appearance: none;
    border-radius: 0; /* Ensure sharp edges */
    border: none; /* Ensure no border on mobile */
    background: transparent; /* Optional: remove any background */
  }

  .dropdown-filter > .title:focus {
    outline: none; /* Remove default focus outline */
    border: none; /* Ensure no border when focused */
  }

  /* If using select elements */
  .dropdown-filter select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
    border: none; /* Remove border from select elements */
    background: transparent; /* Optional: remove any background */
  }

  .dropdown-filter select:focus {
    outline: none;
    border: none;
  }
}

.sample-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 3% 5%; /* Added horizontal padding */
  margin: 0;
  min-height: calc(100vh - 200px); /* Use min-height instead of height */
  overflow: hidden;
}

.img-sample {
  max-width: 40%;
  max-height: calc(100vh - 300px); /* Increased this value to stop scaling higher */
  width: auto;
  height: auto;
  object-fit: contain;
  margin-right: 5%;
  border-radius: 2%;
  border: 2px solid #1A1A1A;
}

.img-desc {
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 300px); /* Match the max-height of the image */
  padding-right: 2%;
  padding-left: 10%; /* Add left padding to move text more to the right */
}

@media (max-width: 768px) {
  .sample-box {
    flex-direction: column;
    align-items: center;
    padding: 3% 5%; /* Increase horizontal padding */
    margin-bottom: 60px;
  }

  .img-sample {
    max-width: 90%;
    max-height: 50vh;
    margin-right: 0;
    margin-bottom: 5%;
  }

  .img-desc {
    width: 90%;
    max-height: none;
    padding: 0 5%; /* Add horizontal padding */
    margin-bottom: 60px;
  }

  .page-footer {
    position: static; /* Change from fixed to static */
    bottom: auto;
    left: auto;
    width: 100%;
    z-index: auto;
  }

  .wishlist-button {
    margin-bottom: 20px;
    width: 90%; /* Make button width consistent with content */
    margin-left: auto;
    margin-right: auto;
  }
}

.hidden {
  display: none !important;
}

#imageLink {
  vertical-align: top;
  display: grid;
  text-align: center;
  width: 16vw;
  height: auto;
  color: #1A1A1A;
  text-decoration: none;
}

ul.pagination {
  list-style: none;
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  list-style: none;
  text-align: center;
  color: #1A1A1A;
  background-color: #E0E0E0;
  margin: 2% 10px 5% 0;
  width: 2.5vw;
  height: 2.5vw;
  line-height: 2.5vw;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.3vw; 
  vertical-align: middle; 
}

ul.pagination li.page-number.current-page {
  background-color: #5A5A5A;
  color: white;
}

.pagination .arrow.disabled {
  color: #ccc; /* Or any dim color */
  opacity: 0.5;
  cursor: default;
}

/* Remove the focus outline - not recommended for accessibility */
.pagination li:focus {
  outline: none;
}

/* OR Customize the focus outline - better for accessibility */
.pagination li:focus {
  outline: 2px solid #4A90E2; /* Custom color, thickness */
}

.img-desc {
  font-size: 2.5vh;
}

.caption {
  font-size: 1.5vw;
  text-align: center;
  margin-bottom: 0;
  margin-top: 5%;
} 

.page-footer {
  position: relative;
  width: 100%;
  background-color: #302c26;
  padding: 1rem 0;
  margin-top: auto;
}

.footer-content {
  display: flex;
  justify-content: center;
  max-width: 1200px; /* Limit the maximum width of the footer content */
  margin: 0 auto; /* Center the footer content */
  color: #FFFFF0;
  font-family: Montserrat;
  margin-bottom: 0.5%;
}

.footer-header {
  margin-bottom: 1rem;
  font-size: 1.2vw;
  font-weight: bold;
}

.column {
  padding-right: 1.5vw; /* adjust as needed */
  padding-left: 1.5vw;
  text-align: center;
}

.column a {
  display: block;
  text-decoration: none;
  color: inherit; /* This will make the link use the text color of the parent element */
  margin-bottom: 0.7vh; /* space between links, adjust as needed */
  font-size: 0.9vw;
  font-weight: lighter;
  text-align: center;
  text-decoration: underline;
}

.copyright-notice {
  text-align: center; /* Center the text */
  color: #636363; /* Dark text for contrast */
  font-size: 0.8vw; /* Adjust the font size as needed */
  margin-bottom: .3vw;
}

/* Media query for larger screens */
@media (min-width: 1600px) {
  .footer-header {
    font-size: 19px; /* Set a fixed size for very large screens */
  }

  .column a {
    font-size: 14px; /* Set a fixed size for very large screens */
  }

  .copyright-notice {
    font-size: 13px; /* Set a fixed size for very large screens */
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  #root {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  .container, .wishlist-container {
    flex: 1 0 auto;
    padding-bottom: 0;
  }

  .page-footer {
    flex-shrink: 0;
    position: static;
    margin-top: auto;
    padding-bottom: 10px;
  }

  .footer-content {
    padding: 10px 0;
  }

  .page-footer {
    padding-bottom: 0.05rem;
    margin-bottom: 0; /* Remove bottom margin */
  }

  .footer-content {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0.25rem 0;
    margin-bottom: 0; /* Remove bottom margin */
  }

  .column {
    margin: 0 10px;
    margin-bottom: 0; /* Remove bottom margin */
  }

  .footer-header {
    font-size: 3vw;
    margin-bottom: 0.25rem;
  }

  .column a {
    font-size: 2.5vw;
    margin-bottom: 0.1vh;
  }

  .copyright-notice {
    font-size: 2vw;
    margin-bottom: 0.1vw;
  }
}

.landing-container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 5%;
}

.landing-container .message {
  text-align: center;
  max-width: 800px;
  margin-bottom: 2rem;
}

.landing-container .message h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.landing-container .message p {
  font-size: 1rem;
  line-height: 1.5;
}

.landing-container .photos {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.landing-container .landing-image {
  height: 300px;
  max-width: calc(25% - 15px);
  object-fit: contain;
  object-position: center;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .landing-container .message h1 {
    font-size: 2rem;
  }

  .landing-container .message p {
    font-size: 0.9rem;
  }

  .landing-container .photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
  }

  .landing-container .landing-image {
    height: auto;
    max-width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f8f8;
}

.logo {
  flex-shrink: 0; /* Prevent the logo from shrinking */
  width: auto; /* Ensure the logo container has enough space */
}

.logo-img {
  max-height: 75px; /* Increase the height as needed */
  width: auto; /* Maintain aspect ratio */
}

.navbar {
  display: flex;
  gap: 20px;
  flex-grow: 1; /* Allow the navbar to take up remaining space */
  justify-content: flex-end; /* Align navbar items to the right */
  padding-right: 20px; /* Add space to the right side */
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.nav-link:hover {
  color: #007bff;
}

/* Add this to your CSS file */
.wishlist-title {
  text-align: center;
}

.wishlist-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.wishlist-buttons {
  display: flex;
  flex-direction: column; /* Change to column layout */
  align-items: center; /* Center buttons horizontally */
  gap: 10px; /* Add space between buttons */
}

.wishlist-count {
  font-size: 16px;
  font-weight: bold;
}

.save-pdf-button,
.clear-wishlist-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block; /* Change to block for full width */
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  width: 200px; /* Set a fixed width for both buttons */
  height: 40px; /* Set a fixed height for both buttons */
  line-height: 20px; /* Center text vertically */
}

.save-pdf-button:hover,
.clear-wishlist-button:hover {
  opacity: 0.9;
}

.clear-wishlist-button {
  background-color: #f44336;
}

/* Remove these styles if they exist */
.clear-wishlist-button-container {
  display: none;
}

/* Add styles for the wishlist page */
#wishlist {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

/* Media query for larger screens */
@media (min-width: 1000px) {
  #wishlist {
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns */
    gap: 20px; /* Adjust the gap between images */
    justify-items: center;
    margin-right: 2%;
  }
}

/* Media query for smaller screens */
@media (max-width: 1000px) {
  #wishlist {
    grid-template-columns: repeat(3, 1fr); 
  }
  .wishlist-sample {
    width: 20vw;
    height: 20vw;
  }
  .wishlist-caption {
    font-size: 2.5vw;
  }
}

/* Media query for even smaller screens */
@media (max-width: 768px) {
  #wishlist {
    grid-template-columns: repeat(2, 1fr); 
  }
  .wishlist-sample {
    width: 25vw;
    height: 25vw;
  }
  .wishlist-caption {
    font-size: 6vw;
  }
}

.wishlist-sample {
  width: 16vw;
  height: 16vw;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 2% 5% 2% 0;
  transition: transform 0.2s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200%;
}

.wishlist-sample:hover {
  transform: scale(1.05);
}

.wishlist-caption {
  font-size: 1.5vw;
  text-align: center;
  margin-bottom: 0;
  margin-top: 5%;
}

/* Pagination styles for wishlist */
ul.wishlist-pagination {
  list-style: none;
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 0;
}

ul.wishlist-pagination li {
  display: inline-block;
  list-style: none;
  text-align: center;
  color: #1A1A1A;
  background-color: #E0E0E0;
  margin: 2% 10px 5% 0;
  width: 2.5vw;
  height: 2.5vw;
  line-height: 2.5vw;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.3vw; 
  vertical-align: middle; 
}

ul.wishlist-pagination li.page-number.current-page {
  background-color: #5A5A5A;
  color: white;
}

.wishlist-pagination .arrow.disabled {
  color: #ccc; /* Or any dim color */
  opacity: 0.5;
  cursor: default;
}

/* Remove the focus outline - not recommended for accessibility */
.wishlist-pagination li:focus {
  outline: none;
}

/* OR Customize the focus outline - better for accessibility */
.wishlist-pagination li:focus {
  outline: 2px solid #4A90E2; /* Custom color, thickness */
}

.wishlist-button {
  background-color: #007bff; /* Primary color */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 200px; /* Fixed width to prevent text movement */
}

.wishlist-button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
  transform: scale(1.05);
}

.wishlist-button:active {
  background-color: #004494; /* Even darker shade for active state */
  transform: scale(1);
}

.add-to-wishlist-button {
  background-color: #28a745; /* Green color */
}

.add-to-wishlist-button:hover {
  background-color: #218838; /* Darker green */
}

.remove-from-wishlist-button:active {
  background-color: #bd2130; /* Even darker red */
}

.clear-wishlist-button {
  background-color: #ffc107; /* Yellow color */
  margin: 0 auto; /* Center the button */
  display: block; /* Ensure the button is a block element */
}

.clear-wishlist-button:hover {
  background-color: #e0a800; /* Darker yellow */
}

.clear-wishlist-button:active {
  background-color: #d39e00; /* Even darker yellow */
}

.cart-icon {
  position: relative;
  font-size: 1.2rem;
}

.cart-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1 0 auto;
}

.sample-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 3% 5%;
  margin: 0;
}

@media (max-width: 768px) {
  .landing-container .content {
    flex-direction: column;
    padding: 2% 5%;
    margin-bottom: 60px; /* Add space for the footer */
  }

  .landing-container .message {
    width: 100%;
    order: 1; /* Move the message to the top */
  }

  .landing-container .photos {
    order: 2; /* Move the photos below the message */
    margin-top: 20px;
    margin-left: 0;
  }

  .landing-container .message h1 {
    font-size: 6vw;
  }

  .landing-container .message p {
    font-size: 4vw;
  }

  .page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
}

@media (max-width: 768px) {
  /* General styles */
  body {
    font-size: 14px;
  }

  .container {
    padding: 10px;
    padding-bottom: 0; /* Remove bottom padding */
  }

  /* Header styles */
  .header-bar {
    flex-direction: column;
    align-items: center;
  }

  .logo-img {
    width: 50%;
    margin: 10px 0;
  }

  .navbar {
    width: 100%;
    justify-content: space-around;
    padding-right: 0;
  }

  /* Home page styles */
  .big-container {
    grid-template-columns: 1fr;
  }

  .filter-container {
    width: 100%;
    margin-bottom: 20px;
  }

  #gallery {
    grid-template-columns: repeat(2, 1fr);
  }

  .rug-sample {
    width: 40vw;
    height: 40vw;
  }

  .caption {
    font-size: 3vw;
  }

  /* Filter styles */
  .search-container label {
    font-size: 16px;
  }

  .search-container input[type="text"] {
    font-size: 14px;
  }

  .dropdown-filter > .title {
    font-size: 16px;
  }

  .dropdown-filter > .content {
    font-size: 14px;
  }

  /* About page styles */
  .sample-box {
    flex-direction: column;
    align-items: center;
    padding: 3% 5%;
    margin-bottom: 20px; /* Reduce margin */
  }

  .img-sample {
    max-width: 90%;
    max-height: 50vh;
    margin-right: 0;
    margin-bottom: 5%;
  }

  .img-desc {
    width: 90%;
    max-height: none;
    padding: 0 5%;
    margin-bottom: 20px; /* Reduce margin */
  }

  /* Landing page styles */
  .landing-container .content {
    flex-direction: column;
    padding: 2% 5%;
    margin-bottom: 20px; /* Reduce margin */
  }

  .landing-container .message {
    width: 100%;
  }

  .landing-container .message h1 {
    font-size: 6vw;
  }

  .landing-container .message p {
    font-size: 4vw;
  }

  .landing-container .photos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 20px;
    margin-left: 0;
  }

  .landing-container .photo {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    max-height: 50vh;
  }

  .landing-container .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .landing-container .photo:nth-child(odd) {
    margin-top: 0;
  }

  /* Wishlist page styles */
  #wishlist {
    grid-template-columns: repeat(2, 1fr);
  }

  .wishlist-sample {
    width: 40vw;
    height: 40vw;
  }

  .wishlist-caption {
    font-size: 3vw;
  }

  /* Footer styles */
  .footer-content {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0.25rem 0;
  }

  .column {
    margin: 0 10px;
  }

  .footer-header {
    font-size: 3vw;
    margin-bottom: 0.25rem;
  }

  .column a {
    font-size: 2.5vw;
    margin-bottom: 0.1vh;
  }

  .copyright-notice {
    font-size: 2vw;
    margin-bottom: 0.1vw;
  }

  /* Pagination styles */
  ul.pagination li, ul.wishlist-pagination li {
    width: 8vw;
    height: 8vw;
    line-height: 8vw;
    font-size: 4vw;
  }

  /* Footer styles */
  .footer-content {
    padding: 0.5rem 0;
  }

  .column {
    margin-bottom: 10px;
  }

  .footer-header {
    margin-bottom: 0.3rem;
  }

  .column a {
    margin-bottom: 0.2vh;
  }

  .page-footer {
    position: static; /* Change from fixed to static */
    bottom: auto;
    left: auto;
    width: 100%;
    z-index: auto;
  }

  .wishlist-button {
    margin-bottom: 20px;
    width: 90%; /* Make button width consistent with content */
    margin-left: auto;
    margin-right: auto;
  }

  .container, .wishlist-container {
    min-height: auto; /* Remove minimum height */
    padding-bottom: 0; /* Remove bottom padding */
  }

  .page-footer {
    position: static; /* Keep this as static */
    margin-top: 20px; /* Add some space above the footer */
  }

  /* Remove or reduce any bottom margins on content containers */
  .landing-container .content,
  .sample-box,
  .img-desc,
  .wishlist-button {
    margin-bottom: 10px; /* Reduce this value */
  }

  /* Ensure the body takes up at least the full viewport height */
  body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  /* Make the main content area grow to push footer down */
  #root {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  /* Ensure the footer stays at the bottom */
  .page-footer {
    flex-shrink: 0;
  }
}

.dropdown-filter .content {
  display: none;
}

.dropdown-filter .content.open {
  display: block;
}

.wishlist-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.wishlist-info p {
  font-size: 18px;
  font-weight: bold;
}

.save-pdf-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.save-pdf-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .container {
    flex: 1;
    min-height: calc(100vh - 200px); /* Adjust 200px based on header/footer height */
  }

  .page-footer {
    margin-top: auto;
  }
}

body, html {
  margin: 0;
  padding: 0;
}

.process-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.process-content h1 {
  text-align: center;
  margin-bottom: 30px;
}

.process-content section {
  margin-bottom: 30px;
}

.process-content h2 {
  margin-bottom: 10px;
}

.process-content p {
  line-height: 1.6;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.home-content h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.home-nav {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  flex-wrap: nowrap;
  width: 100%;
}

.nav-button {
  background-color: #A7BED1;
  color: #1A1A1A;
  border: none;
  padding: 0.5rem 0.75rem; /* Reduce horizontal padding */
  margin: 0 0.25rem; /* Reduce margin */
  font-size: 0.9rem; /* Slightly reduce font size */
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-button:hover {
  background-color: #8CABC0;
}

.landing-image {
  width: 100%;
  max-width: 1200px;
  height: auto;
}

.landing-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .home-content h1 {
    font-size: 2rem;
  }

  .home-nav {
    flex-wrap: wrap;
  }

  .nav-button {
    margin: 0.5rem;
  }
}
.small-landing-image {
  max-width: 50%; /* Adjust this value to make the image smaller or larger */
  height: auto;
  display: block;
  margin: 0 auto;
}

.contact-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.contact-content h1 {
  font-size: 2.5rem;
  color: #302c26;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-intro {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1.5rem;
  text-align: center;
}

.contact-info {
  background-color: #f8f8f8;
  padding: 1.5rem 2rem; /* Reduced top and bottom padding */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-info h2 {
  color: #302c26;
  margin-top: 0; /* Remove top margin */
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.contact-info p {
  margin-bottom: 0.7rem;
  font-size: 1.1rem;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-content {
    padding: 1rem;
  }

  .contact-content h1 {
    font-size: 2rem;
  }

  .contact-intro {
    font-size: 1rem;
  }

  .contact-info {
    padding: 1.25rem 1.5rem; /* Further reduced padding for mobile */
  }

  .contact-info h2 {
    font-size: 1.5rem;
  }

  .contact-info p {
    font-size: 1rem;
  }
}

.process-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.process-content h1 {
  font-size: 2.5rem;
  color: #302c26;
  margin-bottom: 1rem;
  text-align: center;
}

.process-intro {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1.5rem;
  text-align: center;
}

.process-info {
  background-color: #f8f8f8;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.process-info h2 {
  color: #302c26;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.process-info ol, .process-info ul {
  margin-bottom: 1rem;
}

.process-info li {
  margin-bottom: 0.7rem;
  font-size: 1.1rem;
}

.process-info h3 {
  color: #302c26;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
}

.process-info a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.process-info a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .process-content {
    padding: 1rem;
  }

  .process-content h1 {
    font-size: 2rem;
  }

  .process-intro {
    font-size: 1rem;
  }

  .process-info {
    padding: 1.25rem 1.5rem;
  }

  .process-info h2 {
    font-size: 1.5rem;
  }

  .process-info li {
    font-size: 1rem;
  }

  .process-info h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .home-content h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .home-nav {
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    gap: 0.3rem; /* Reduces space between buttons */
  }

  .nav-button {
    padding: 0.7rem 0.5rem; /* Increased vertical padding */
    margin: 0;
    font-size: 0.9rem; /* Slightly larger font */
    flex: 1; /* Makes buttons grow to fill available space */
    min-width: 0; /* Allows buttons to shrink if needed */
    display: flex; /* Added to enable centering */
    justify-content: center; /* Centers text horizontally */
    align-items: center; /* Centers text vertically */
    text-align: center; /* Ensures text is centered if it wraps */
  }

  .small-landing-image {
    max-width: 90%; /* Made image even larger */
    margin-top: 1rem; /* Added some space above the image */
  }
}