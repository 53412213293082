body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.message {
  max-width: 450px; /* Adjust this value as needed */
  margin: 0 0 0 0; /* Move the text box as close to the left as possible */
}

.landing-image {
  width: 100%; /* Make the container take up the full width */
  height: 100vh; /* Changed from 800px to 100vh */
  overflow: hidden; /* Ensure images don't overflow the container */
}

.landing-image img {
  width: 100%; /* Make the images take up the full width of their container */
  height: 100%; /* Make the images take up the full height of their container */
  object-fit: cover; /* Ensure the images cover the container without distortion */
}